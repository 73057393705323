// Check supported media formats
let supportedMediaFormat = null;

function checkSupportedFormats() {
    if (MediaRecorder.isTypeSupported('video/webm')) {
        console.log('video/webm is supported');
        supportedMediaFormat = 'video/webm';
    } else if (MediaRecorder.isTypeSupported('audio/webm')) {
        console.log('audio/webm is supported');
        supportedMediaFormat = 'audio/webm';
    } else if (MediaRecorder.isTypeSupported('video/mp4')) {
        console.log('video/mp4 is supported');
        supportedMediaFormat = 'video/mp4';
    } else if (MediaRecorder.isTypeSupported('audio/mp4')) {
        console.log('audio/mp4 is supported');
        supportedMediaFormat = 'audio/mp4';
    } else if (MediaRecorder.isTypeSupported('audio/ogg')) {
        console.log('audio/ogg is supported');
        supportedMediaFormat = 'audio/ogg';
    } else {
        console.log('No supported media formats found');
        supportedMediaFormat = null;
    }
}

    // Call the function when the document is loaded
    document.addEventListener('DOMContentLoaded', checkSupportedFormats);

let currentSessionId = null;
let recording = false;
let chunkSet = [];
let chunkNumber = 0;
let heartbeatInterval = null;
let summaryPollingInterval = null;
let wakeLock = null;
let mediaRecorder;
let audioStream;
let isRecording = false;
let timerInterval;
let seconds = 0;
let progressUpdater;

// Utility functions
function log(message, level = 'info') {
    console[level](`[${new Date().toISOString()}] ${message}`);
}

function parseMarkdown(text) {
// H3 headers (bold)
text = text.replace(/^###\s+(.*$)/gm, '<h3><strong>$1</strong></h3>');
// Bold
text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
// Ensure newlines are preserved
text = text.replace(/\n/g, '<br>');
return text;
}


document.addEventListener('DOMContentLoaded', () => {
    const recordButton = document.getElementById('recordButton');
    recordButton.addEventListener('click', toggleRecording);
    updateUI();
});
        
const recordButton = document.getElementById('recordButton');
const timerElement = document.getElementById('timer');
const progressBarContainer = document.getElementById('progressBarContainer');
const progressBar = document.getElementById('progressBar');
const animationContainer = document.getElementById('animationContainer');
const summaryElement = document.getElementById('summary');

async function startRecording() {
    try {
        await resetState();
        await acquireWakeLock();

        audioStream = await navigator.mediaDevices.getUserMedia({
            audio: {
                channelCount: 1,
                sampleRate: 16000,
            }
        });
        
        await startNewRecordingSegment();
        recording = true;
        recordButton.textContent = "Stop Recording";
        recordButton.style.backgroundColor = "var(--secondary-color)";
        startTimer();
        startRecordingTimeout();

    } catch (error) {
        console.error("Error starting recording:", error);
        handleError("Failed to start recording. Please check your microphone permissions and try again.", true);
    }
}

async function startNewRecordingSegment() {
    console.log("Starting new recording segment");
    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
        console.log("Stopping previous mediaRecorder");
        mediaRecorder.stop();
        await new Promise(resolve => mediaRecorder.onstop = resolve);
    }

    if (!supportedMediaFormat) {
        throw new Error("No supported media format detected");
    }

    let options = { mimeType: supportedMediaFormat };
    
    mediaRecorder = new MediaRecorder(audioStream, options);
    console.log("New MediaRecorder created with MIME type:", mediaRecorder.mimeType);

    mediaRecorder.ondataavailable = async (event) => {
        if (event.data.size > 0) {
            console.log(`Data available for chunk ${chunkNumber}, size: ${event.data.size} bytes`);
            await sendChunk(event.data, chunkNumber, !recording);
            console.log(`Sent chunk ${chunkNumber}. Total sent chunks: ${chunkNumber + 1}`);
            chunkNumber++;
        }
    };

    mediaRecorder.start();
    console.log("Started new recording segment");

    // Schedule the stop of this segment and start of the next one
    setTimeout(() => {
        if (recording) {
            console.log("30 seconds elapsed, stopping current segment");
            mediaRecorder.stop();
            setTimeout(() => {
                if (recording) {
                    console.log("Starting next segment");
                    startNewRecordingSegment();
                }
            }, 0);
        }
    }, 30000); // 30 seconds
}


async function stopRecording() {
    console.log(`Stopping recording. Sent chunks: ${chunkNumber}`);
    recording = false;
    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
        mediaRecorder.stop();
        await new Promise(resolve => mediaRecorder.onstop = resolve);
    }
    audioStream.getTracks().forEach(track => track.stop());
    stopTimer();
    updateUI();
    progressUpdater = startProgressUpdates();
        
    console.log("Last chunk sent, waiting for processing to start...");
    
    // Wait a bit for the server to start processing
    await new Promise(resolve => setTimeout(resolve, 2000));

    // Start fetching summary
    fetchSummary();

    releaseWakeLock();
}


function updateUI() {
    if (recording) {
        recordButton.textContent = 'Stop Recording';
        recordButton.classList.add('red-button');
        progressBarContainer.style.display = 'none';
    } else {
        recordButton.textContent = 'Start Recording';
        recordButton.classList.remove('red-button');
        recordButton.style.setProperty('background-color', 'var(--primary-color)');
        timerElement.textContent = '00:00:00';
    }

    updateTimerDisplay();
}

async function acquireWakeLock() {
    console.log("Attempting to acquire wake lock...");
    if ('wakeLock' in navigator) {
        try {
            wakeLock = await navigator.wakeLock.request('screen');
            console.log('Wake Lock is active');
        } catch (err) {
            console.error(`${err.name}, ${err.message}`);
        }
    } else {
        console.warn('Wake Lock API not supported');
    }
}


function releaseWakeLock() {
    if (wakeLock !== null) {
        wakeLock.release()
            .then(() => {
                console.log('Wake Lock is released');
                wakeLock = null;
            })
            .catch((err) => {
                console.error(`${err.name}, ${err.message}`);
            });
    }
}


async function fetchSummary() {
    const maxAttempts = 40; // 2 minutes / 3 seconds = 40 attempts
    let attempt = 0;
    const baseDelay = 3000; // 3 seconds

    while (attempt < maxAttempts) {
        console.log(`Fetching summary attempt ${attempt + 1} of ${maxAttempts}`);
        try {
            const response = await fetch(`/api/get_summary?session_id=${currentSessionId}`);
            console.log(`Fetch response status: ${response.status}`);
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            console.log(`Received response:`, data);

            switch (data.status) {
                case 'complete':
                    console.log(`Summary received on attempt ${attempt + 1}`);
                    displaySummary(data.summary);
                    return;
                case 'processing':
                    console.log("Summary is still processing");
                    break;
                case 'error':
                    console.error("Error in summary generation:", data.error);
                    displayError(`Error generating summary: ${data.error}`);
                    return;
                default:
                    console.error("Unexpected response status:", data.status);
                    displayError("Unexpected response from server");
                    return;
            }
        } catch (error) {
            console.error(`Error fetching summary on attempt ${attempt + 1}:`, error);
        }

        attempt++;
        await new Promise(resolve => setTimeout(resolve, baseDelay));
    }

    console.error("Summary fetch timeout after 2 minutes");
    displayError("Summary fetch timeout after 2 minutes. Please try again later.");
}



function startRecordingTimeout() {
    recordingTimeout = setTimeout(() => {
        if (recording) {
            console.log("Recording timeout reached. Stopping recording.");
            stopRecording();
        }
    }, 2 * 60 * 60 * 1000 + 10 * 60 * 1000); // 2 hours and 10 minutes in milliseconds
}


async function toggleRecording() {
    if (!recording) {
        await startRecording();
    } else {
        console.log("Calling stopRecording...");
        await stopRecording();
    }
    updateUI();
}


function displaySummary(summary) {
    const summaryElement = document.getElementById('summary');
    summaryElement.innerHTML = parseMarkdown(summary);
    summaryElement.dataset.originalMarkdown = summary;
    // Reset button state
    const startStopButton = document.getElementById('recordButton');
    startStopButton.textContent = 'Start Recording';
    startStopButton.classList.remove('btn-secondary', 'red-button');
    startStopButton.classList.add('btn-primary');

    showCopyButton();

    // Complete the progress bar animation
    if (progressUpdater) {
        progressUpdater.complete();
    }
}


function displayError(message) {
    const summaryElement = document.getElementById('summary');
    summaryElement.textContent = `Error: ${message}`;
}

async function sendChunk(chunk, chunkNumber, isLastChunk) {
    console.log(`Sending chunk ${chunkNumber}, size: ${chunk.size}, isLast: ${isLastChunk}`);
    
    if (!currentSessionId) {
        console.error("No active recording session");
        throw new Error("No active recording session. Please try again.");
    }

    const formData = new FormData();
    
    let fileExtension = chunk.type.includes('webm') ? 'webm' : 'm4a';
    let mimeType = chunk.type.includes('webm') ? 'audio/webm' : 'audio/mp4';
    
    const fileName = `chunk_${chunkNumber}.${fileExtension}`;
    
    formData.append('file', new Blob([chunk], { type: mimeType }), fileName);
    formData.append('session_id', currentSessionId);
    formData.append('chunk_number', chunkNumber.toString());
    formData.append('is_last_chunk', isLastChunk.toString());
    formData.append('chunk_size', chunk.size.toString());

    // Only send if the chunk has content or it's the last chunk
    if (chunk.size > 0 || isLastChunk) {
        try {
            const response = await fetch('/api/upload_chunk', {
                method: 'POST',
                body: formData
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const result = await response.json();
            console.log(`Chunk ${chunkNumber} sent successfully:`, result);
        } catch (error) {
            console.error(`Error sending chunk ${chunkNumber}:`, error);
            throw error;  // Re-throw the error for the caller to handle
        }
    } else {
        console.log(`Skipping empty non-final chunk ${chunkNumber}`);
    }
}

recordButton.addEventListener('click', toggleRecording);

function startProgressUpdates() {
    const progressBarContainer = document.getElementById('progressBarContainer');
    const progressBar = document.getElementById('progressBar');
    const statusElement = document.getElementById('status');
    const animationContainer = document.getElementById('animationContainer');
    
    progressBarContainer.style.display = 'block';
    statusElement.style.display = 'block';
    animationContainer.style.display = 'block';
    
    let animationRunning = true;
    let isComplete = false;

    const animationFrames = [
        '(^・ω・^)',
        '(^・ω・^)ノ',
        '(^・ω・^)ﾉ',
        '(^・ω・^)/',
        '(^・ω・^)',
        '(^・ω・^)\\',
        '(^・ω・^)ﾉ',
        '(^・ω・^)ノ'
    ];
    let currentFrame = 0;

    function updateAnimation() {
        if (!animationRunning) return;
        animationContainer.textContent = animationFrames[currentFrame];
        currentFrame = (currentFrame + 1) % animationFrames.length;
        setTimeout(updateAnimation, 200);
    }

    function setProgress(percent) {
        if (!isComplete) {
            progressBar.style.width = `${percent}%`;
        }
    }

    function setStatus(text) {
        if (!isComplete) {
            statusElement.textContent = text;
        }
    }

    async function animateProgress(start, end, duration, status) {
        const startTime = Date.now();
        while (!isComplete && Date.now() - startTime < duration) {
            const elapsedTime = Date.now() - startTime;
            const progress = start + (end - start) * (elapsedTime / duration);
            setProgress(progress);
            setStatus(status);
            await new Promise(resolve => setTimeout(resolve, 50));
        }
        if (!isComplete) {
            setProgress(end);
        }
    }

    async function wait(ms) {
        await new Promise(resolve => setTimeout(resolve, ms));
    }

    async function runAnimation() {
        updateAnimation();
        while (animationRunning && !isComplete) {
            await animateProgress(20, 40, 1500, 'Processing audio');
            if (isComplete) break;
            await wait(500);
            await animateProgress(40, 70, 2500, 'Transcribing');
            if (isComplete) break;
            await wait(4000);
            await animateProgress(70, 80, 4000, 'Generating summary');
            if (isComplete) break;
            setStatus('Waiting for summary...');
            await wait(30000);
        }
    }

    runAnimation();

    return {
        complete: async function() {
            isComplete = true;
            animationRunning = false;
            progressBar.style.width = '100%';
            statusElement.textContent = 'Complete';
            await wait(1000); // Show 'Complete' for 2 seconds
            progressBarContainer.style.display = 'none';
            statusElement.style.display = 'none';
            animationContainer.style.display = 'none';
        }
    };
}


const retryButton = document.getElementById('retryButton');

function handleError(message) {
    console.error("Error:", message);
    // Update UI to show error state
    recordButton.textContent = "Start Recording";
    recordButton.style.backgroundColor = "var(--primary-color)";
    recording = false;
    stopTimer();
    releaseWakeLock();
}
retryButton.addEventListener('click', async () => {
    retryButton.style.display = 'none';
    try {
        if (await checkServerStatus()) {

        } else {
            throw new Error("Server is not responding");
        }
    } catch (error) {
        handleError("Failed to connect to the server. Please try again.");
    }
});

async function resetState() {
    console.log("Resetting state...");
    chunkNumber = 0;
    chunkSet = [];
    is_last_chunk = false;
    currentSessionId = null;

    // Reset progress bar and animation
    const progressBarContainer = document.getElementById('progressBarContainer');
    const progressBar = document.getElementById('progressBar');
    const statusElement = document.getElementById('status');
    const animationContainer = document.getElementById('animationContainer');

    progressBar.style.width = "0%";
    statusElement.textContent = "";
    progressBarContainer.style.display = 'none';
    animationContainer.style.display = 'none';

    summaryElement.innerHTML = "";
    copyButton.style.display = 'none';
    console.log("Stopping audio stream and media recorder...");
    if (audioStream) {
        audioStream.getTracks().forEach(track => {
            track.stop();
            console.log("Audio track stopped");
        });
        audioStream = null;
    }

    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
        mediaRecorder.stop();
        console.log("Media recorder stopped");
    }
    mediaRecorder = null;

    try {
        console.log("Fetching new session ID...");
        const response = await fetch('/api/start_session', {
            method: 'POST'
        });
        if (response.ok) {
            const data = await response.json();
            currentSessionId = data.session_id;
            console.log("New session ID acquired:", currentSessionId);
        } else {
            console.error("Server response not OK:", response.status, response.statusText);
            throw new Error("Failed to get a new session from the server");
        }
        console.log("State reset completed successfully");
        return true;
    } catch (error) {
        console.error("Failed to reset state:", error);
        handleError("Failed to reset. Please refresh the page.");
        return false;
    }
}
        
document.addEventListener('visibilitychange', async () => {
    if (wakeLock !== null && document.visibilityState === 'visible') {
        await acquireWakeLock();
    }
});

function startTimer() {
    seconds = 0;
    updateTimerDisplay();
    stopTimer();  // Ensure any existing timer is stopped
    timerInterval = setInterval(() => {
        seconds++;
        updateTimerDisplay();
    }, 1000);
}

function stopTimer() {
    if (timerInterval) {
        clearInterval(timerInterval);
        timerInterval = null;
    }
}

function updateTimerDisplay() {
    const hours = Math.floor(seconds / 3600).toString().padStart(2, '0');
    const minutes = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
    const secs = (seconds % 60).toString().padStart(2, '0');
    document.getElementById('timer').textContent = `${hours}:${minutes}:${secs}`;
}


const copyButton = document.getElementById('copyButton');

copyButton.addEventListener('click', async () => {
    const summaryElement = document.getElementById('summary');
    let textToCopy = summaryElement.dataset.originalMarkdown || summaryElement.innerText;
    
    // Convert to Slack-friendly markdown
    textToCopy = convertToSlackMarkdown(textToCopy);

    try {
        await navigator.clipboard.writeText(textToCopy);
        const originalContent = copyButton.innerHTML;
        copyButton.innerHTML = '<span class="icon">✅</span> Copied!';
        setTimeout(() => {
            copyButton.innerHTML = originalContent;
        }, 2000);
    } catch (err) {
        console.error('Failed to copy text: ', err);
        const originalContent = copyButton.innerHTML;
        copyButton.innerHTML = '<span class="icon">❌</span> Failed';
        setTimeout(() => {
            copyButton.innerHTML = originalContent;
        }, 2000);
    }
});


// Function to show the copy button
function showCopyButton() {
    copyButton.style.display = 'flex';
}

function convertToSlackMarkdown(text) {
    // Convert **bold** to *bold* for Slack
    text = text.replace(/\*\*(.*?)\*\*/g, '*$1*');
    
    // Convert bullet points from '* ' to '- '
    text = text.replace(/^\* /gm, '- ');
    
    // Convert ### headers to *bold* in Slack (if you're using this)
    text = text.replace(/^###\s+(.*$)/gm, '*$1*');
    
    return text;
}
